import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

const SlideUp = ({ children, delay = 0, duration = 500, easing = "ease" }) => {
  const [show, setShow] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (elementTop < windowHeight) {
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const props = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? "translateY(0)" : "translateY(20%)",
    config: { duration, delay, easing: config[easing] },
  });
  
  return (
    <animated.div ref={elementRef} style={props} id="react-spring">
      {children}
    </animated.div>
  );
};

export default SlideUp;
